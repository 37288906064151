import React from "react";

import Layout, { Topper } from "../components/layout";
import SEO from "../components/seo";
import Embeds from "../components/embeds"

import Hero from "../components/Home/Hero";
import BackedByYc from "../components/Home/YCBar";
import BrandBar from "../components/Home/BrandBar";
import TheWhat from "../components/Home/TheWhat";
import Pledge from "../components/Home/Pledge";
import Engagement from "../components/Home/Engagement";
import Providers from "../components/Home/Providers";
import Data from "../components/Home/Data";
import GetStarted from "../components/Home/GetStarted";
import CaseStudies from "../components/Home/CaseStudies";
import Team from "../components/Home/Team";

import useWindowSize from "../components/hooks/useWindowSize";

const IndexPage = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <Layout>
      <SEO title="Instant sustainability with automated storytelling" />
      <Embeds />
      <Hero />
      <div
        style={{
          height: "4vh",
          // marginTop: "-20vh",
          zIndex: 100,
          position: "relative",
        }}
      >
        <Topper />
      </div>
      <BackedByYc />
      <BrandBar />
      <Pledge screenWidth={screenWidth} id="section_product" />
      <Engagement screenWidth={screenWidth} />
      <Providers id="section_impact" />
      <Data screenWidth={screenWidth} />
      <GetStarted id="section_get_started" />
      <CaseStudies id="section_customers" />
      <Team id="section_about" />
      <TheWhat />
    </Layout>
  );
};

export default IndexPage;
