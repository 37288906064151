import React from "react";
import styled from "styled-components";
import { PopupButton } from "@typeform/embed-react";
import { StaticImage } from "gatsby-plugin-image";

import {
  Section,
  Wrapper,
  Topper,
  CenteredText,
  Emoji,
  ContextButton,
} from "../layout";
import Media from "../media";
import { track } from "../../utils/analytics";

const SectionContainer = styled(Section)`
  position: relative;
  background: #fff7d6;
  padding-top: 4rem;

  h2 {
    max-width: none;
  }
  p {
    max-width: none;
  }
`;

const Blocks = styled.div`
  display: flex;
  margin-bottom: 4rem;

  flex-direction: column;

  ${Media.Tablet`
    flex-direction: row;
  `}
`;

const StartingBlock = styled.div`
  background: white;
  border-radius: 0.4rem;
  overflow: hidden;
  /* border: 2px solid #ddd; */
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);
  flex: 1;

  text-align: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${Media.Tablet`
    &:first-child {
      margin-right: 1rem;
      margin-bottom: 0;
    }
    
    &:last-child {
      margin-left: 1rem;
      margin-bottom: 0;
    }

    &:not(:first-child):not(:last-child) {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}

  div.top-bar {
    padding: 0.4rem 1.4rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcf3eb;

    & h4 {
      font-size: 0.7rem;
      margin: 0;
      padding: 0;
    }
  }

  div.block-body {
    padding: 2rem 1.4rem 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
      height: 4.2rem;
      width: 4.2rem;
      object-fit: contain;
      margin-bottom: 1.4rem;
    }

    & .title-holder {
      & h3 {
        margin: 0;
        padding: 0;
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 1rem;
    }
  }

  h4 {
    margin-top: 0.4rem;
  }
`;

const BlockButton = styled.a`
  padding: 0.6rem 1rem;
  background: ${({ plain }) => (plain ? "#eee" : "#428e79")};
  color: ${({ plain }) => (plain ? "#005A5C" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* align-self: flex-start; */
  border-radius: 0.2rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background: ${({ plain }) => (plain ? "#eff8f5" : "#539c87")};
  }
`;

const StatusChip = styled.span`
  padding: 0.2rem 0.2rem;
  margin: 0 0.4rem;
  min-width: 3rem;
  font-size: 0.7rem;
  font-weight: 800;
  background: #ddd;
  border-radius: 1rem;
  color: #005a5c;
  background: #b7f2f4;
`;

const GetStartedSection = ({ id }) => {
  const providers = [
    {
      name: "Shopify",
      topBar: "14-day trial",
      image: (
        <StaticImage
          src="../../images/logos/shopify-bag.png"
          alt={`Shopify logo`}
          placeholder="tracedSVG"
          objectFit="contain"
        />
      ),
      body: (
        <>
          Our award-winning Shopify app connects to your online store and
          handles sales with impact automatically.
        </>
      ),
      action: {
        title: "Install app",
        subdued: false,
        url: "https://apps.shopify.com/verdn-sell-with-impact",
      },
    },
    {
      name: "Zapier",
      topBar: "Perfect for no-code",
      chip: "New!",
      image: (
        <StaticImage
          src="../../images/logos/zapier-icon.png"
          alt={`Zapier logo`}
          placeholder="tracedSVG"
          objectFit="contain"
        />
      ),
      body: (
        <>
          Add pledges to anything and everything with our no-code Zapier
          integration. Get set up in minutes.
        </>
      ),
      action: {
        title: "Sign up",
        subdued: false,
        typeformCode: "cRiKhSDT",
      },
    },
    {
      name: "API",
      topBar: "Join the beta",
      image: (
        <StaticImage
          src="../../images/logos/verdn-docs.png"
          alt={`Verdn API logo`}
          placeholder="tracedSVG"
          objectFit="contain"
        />
      ),
      body: (
        <>
          The Verdn API gives you total control over your impact pledge
          implementation. Reach out to us with your use-case!
        </>
      ),
      action: {
        title: "Get in touch",
        subdued: true,
        typeformCode: "jvF4QTY6",
      },
    },
  ];

  return (
    <SectionContainer id={id}>
      <Topper style={{ backgroundColor: "#FFF7D6" }} />
      <Wrapper>
        <CenteredText>
          <h2>Get started in minutes</h2>
          <p>
            Whether you sell products on Shopify, run your own servers, or
            connect to other apps with no-code, there's a way to make Verdn work
            for you and your customers.
          </p>
        </CenteredText>
        <Blocks>
          {providers.map((provider) => {
            let actionComponent = null;

            const analyticsTrackCall = () =>
              track("Landing Page Sales Intent Interaction", {
                label: `Getting started CTA (${provider.name})`,
                providerName: provider.name,
                buttonTitle: provider.action.title,
              });

            if (provider.action.url) {
              actionComponent = (
                <BlockButton
                  target="_blank"
                  href={provider.action.url}
                  onClick={analyticsTrackCall}
                  plain={!!provider.action.subdued}
                >
                  {provider.action.title}
                </BlockButton>
              );
            } else if (provider.action.typeformCode) {
              actionComponent = (
                <PopupButton
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                    margin: 0,
                    width: "100%",
                  }}
                  id={provider.action.typeformCode}
                >
                  <BlockButton
                    onClick={analyticsTrackCall}
                    plain={!!provider.action.subdued}
                  >
                    {provider.action.title}
                  </BlockButton>
                </PopupButton>
              );
            }

            return (
              <StartingBlock key={provider.name}>
                <div className="top-bar">
                  <h4>{provider.topBar}</h4>
                </div>
                <div className="block-body">
                  {provider.image}
                  <div className="title-holder">
                    <h3>{provider.name}</h3>
                    {!!provider.chip && (
                      <StatusChip>{provider.chip}</StatusChip>
                    )}
                  </div>
                  <p>{provider.body}</p>
                  {actionComponent}
                </div>
              </StartingBlock>
            );
          })}
        </Blocks>
        <p>
          <i>
            Need a specific integration not listed here?{" "}
            <ContextButton formId="t4RCnZJ3">Let us know</ContextButton>
          </i>
        </p>
      </Wrapper>
    </SectionContainer>
  );
};

export default GetStartedSection;
