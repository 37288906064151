import React from "react";
import styled from "styled-components";

import { Section, Wrapper, Emoji, Caption, Halves, Half } from "../layout";
// import Media from "../media";

const SectionContainer = styled(Section)`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const CardImage = styled.figure`
  /* position: absolute; */
  object-fit: cover;
  border-radius: 0.4rem;
  border: 1px solid #ddd;
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);
  background: white;
  overflow: hidden;
  /* padding: 1rem; */
`;

// const ({scale}) => scale = 1;

const ShopifyAdmin = styled.div`
  background-color: #f6f6f7;
  /* height: ${({ scale }) => scale * 20}rem; */
  width: 100%;
  padding: ${({ scale }) => scale * 1}rem;

  .admin-bar {
    display: flex;
    align-items: center;
  }

  .back-button {
    width: ${({ scale }) => scale * 2}rem;
    height: ${({ scale }) => scale * 2}rem;
    border: 2px solid #ccc;
    border-radius: 0.4rem;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${({ scale }) => scale * 1}rem;

    span {
      font-size: ${({ scale }) => scale * 1.6}rem;
    }
  }

  .save-button {
    line-height: 100%;
    padding: ${({ scale }) => scale * 0.7}rem ${({ scale }) => scale * 0.8}rem;
    background-color: #438e79;
    font-size: ${({ scale }) => scale * 0.8}rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: ${({ scale }) => scale * 0.4}rem;
    color: white;
    margin-left: auto;
  }

  .admin-bar img {
    width: ${({ scale }) => scale * 3}rem;
    height: ${({ scale }) => scale * 3}rem;
    background: white;
    border: 1px solid #eee;
    object-fit: contain;
    margin-right: ${({ scale }) => scale * 1}rem;
    border-radius: 0.4rem;
  }

  .admin-bar p {
    line-height: 0;
    font-weight: 500;
    font-size: ${({ scale }) => scale * 0.9}rem;
    margin-bottom: 0;
  }

  .ui-container {
    margin: ${({ scale }) => scale * 1}rem 0;
    width: 100%;
    /* height: 100%; */
    display: flex;
  }

  .ui-container > div:first-child {
    flex: 1;
  }
  .ui-container > div:nth-child(2) {
    flex: 0.8;
  }

  .ui-container h4 {
    letter-spacing: unset;
    text-transform: unset;
    font-family: Inter;
    font-size: ${({ scale }) => scale * 0.8}rem;
    font-weight: 600;
    margin-bottom: ${({ scale }) => scale * 1}rem;
    color: #222;
  }

  .select-icons {
    display: flex;
    flex-direction: column;
  }

  .select-icons > div {
    display: flex;
    align-items: center;
    margin-bottom: ${({ scale }) => scale * 1.4}rem;
  }

  .select-icons > div.sub {
    margin-bottom: ${({ scale }) => scale * 0.6}rem;
  }

  .select-icons > div > span:first-child {
    font-size: ${({ scale }) => scale * 1}rem;
    color: #888;
    margin-right: ${({ scale }) => scale * 0.6}rem;
  }

  .select-icons > div > span:nth-child(2) {
    border: 1px solid #ddd;
    padding: ${({ scale }) => scale * 0.5}rem;
    font-size: ${({ scale }) => scale * 1.4}rem;
    margin-right: ${({ scale }) => scale * 0.8}rem;
    background: white;
    border-radius: 0.2rem;
    color: #777;
  }

  .bullet-text {
    display: flex;
    flex-direction: column;
    font-size: ${({ scale }) => scale * 0.8}rem;
    line-height: 100%;
  }

  .bullet-text > span:first-child {
    font-weight: 600;
    font-size: ${({ scale }) => scale * 0.7}rem;
    margin-bottom: ${({ scale }) => scale * 0.6}rem;
  }

  .sub .bullet-text > span:first-child {
    font-weight: 600;
    font-size: ${({ scale }) => scale * 0.6}rem;
    margin-bottom: ${({ scale }) => scale * 0.4}rem;
  }

  .bullet-text > span:nth-child(2) {
    text-transform: uppercase;
    font-size: ${({ scale }) => scale * 0.6}rem;
  }

  .sub .bullet-text > span:nth-child(2) {
    text-transform: unset;
    font-size: ${({ scale }) => scale * 0.6}rem;
    margin-bottom: ${({ scale }) => scale * 0.4}rem;
  }
`;

const AdminSelectIcons = ({ icons }) => (
  <div className="select-icons">
    {icons.map(({ iconName, title, subtitle, selected }) => (
      <div key={title} className={iconName ? "standard" : "sub"}>
        <span className="material-icons">{`radio_button_${
          selected ? "checked" : "unchecked"
        }`}</span>
        {iconName && <span className="material-icons">{iconName}</span>}
        <div className="bullet-text">
          <span>{title}</span>
          <span>{subtitle}</span>
        </div>
      </div>
    ))}
  </div>
);

const ProductPage = styled.div`
  display: flex;
  position: relative;
  background: #fafafa;
`;

const ProductImage = styled.img`
  flex: 0.6;
  height: ${({ scale }) => scale * 12}rem;
  border-right: 1px solid #eee;
  object-fit: cover;
  object-position: top right;
  background: white;
`;

const ProductText = styled.div`
  flex: 1;
  display: flex;
  margin-top: ${({ scale }) => scale * 1}rem;
  flex-direction: column;
  margin-left: ${({ scale }) => scale * 1}rem;

  line-height: 100%;

  & > span:first-child {
    font-size: ${({ scale }) => scale * 1.2}rem;
    font-weight: 500;
    margin-bottom: ${({ scale }) => scale * 0.8}rem;
  }

  & > span:nth-child(2) {
    font-size: ${({ scale }) => scale * 1}rem;
    font-weight: 300;
    margin-bottom: ${({ scale }) => scale * 0.8}rem;
  }
`;

const BadgeOuter = styled.div`
  width: calc(100% - ${({ scale }) => scale * 1}rem);
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ scale }) => scale * 0.3}rem;
  margin: ${({ scale }) => scale * 1}rem 0 ${({ scale }) => scale * 0.2}rem;
  display: flex;
  padding: ${({ scale }) => scale * 0.6}rem ${({ scale }) => scale * 0.5}rem;
  align-items: flex-start;
  transition: background-color 1s, border-color 1s;
`;

const PoweredBy = styled.img`
  height: ${({ scale }) => scale * 0.55}rem;
  opacity: 0.8;
  align-self: flex-end;
  margin-right: ${({ scale }) => scale * 1.3}rem;
  margin-bottom: ${({ scale }) => scale * 0.3}rem;
  margin-top: ${({ scale }) => scale * 0.2}rem;
`;

const BadgeIcon = styled.img`
  width: ${({ scale }) => scale * 2.1}rem;
  height: ${({ scale }) => scale * 2.1}rem;
  border-radius: 4rem;
  margin-right: ${({ scale }) => scale * 0.6}rem;
`;

const BadgeText = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  .badgeTitle {
    font-size: ${({ scale }) => scale * 0.7}rem;
    font-weight: 800;
    line-height: ${({ scale }) => scale * 0.8}rem;
    margin-bottom: ${({ scale }) => scale * 0.3}rem;
  }

  .badgeSubtitle {
    font-size: ${({ scale }) => scale * 0.6}rem;
    font-weight: 400;
    line-height: ${({ scale }) => scale * 0.8}rem;
  }
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  div {
    display: flex;
    margin-bottom: 1rem;
  }

  div > span:first-child {
    margin-right: 1rem;
    color: #438e79;
  }
`;

const CheckedBullets = ({ bullets }) => (
  <BulletContainer>
    {bullets.map((body) => (
      <div key={body}>
        <span className="material-icons">task_alt</span>
        <span>{body}</span>
      </div>
    ))}
  </BulletContainer>
);

const PledgeSection = ({ id, screenWidth }) => {
  const adminScale = (() => {
    if (screenWidth < 600) {
      return 0.6;
    }
    return 1;
  })();
  const badgeScale = (() => {
    if (screenWidth < 600) {
      return 0.6;
    }
    return 1;
  })();

  return (
    <SectionContainer id={id}>
      <Wrapper>
        <Halves>
          <Half>
            <Emoji>volunteer_activism</Emoji>
            <h2>Pledge with your products and services</h2>
            <p>
              Embed <strong>impact pledges</strong> into anything that you sell,
              such as tree planting and ocean-bound plastic cleanup.
            </p>
            <CheckedBullets
              bullets={[
                "Customize the pledge type and amount down to each individual item",
                "Show off your pledges and total impact with editable badges and live counters",
                "Get onboarded quickly with our 5-minute setup",
              ]}
            />
          </Half>
          <Half>
            <Caption>Choose any impact per item</Caption>
            <CardImage>
              <ShopifyAdmin scale={adminScale}>
                <div className="admin-bar">
                  <div className="back-button">
                    <span className="material-icons">arrow_back</span>
                  </div>
                  <img src="/tshirt-blank.png" />
                  <p>Landmark Crew Neck</p>
                  <div className="save-button">Save</div>
                </div>
                <div className="ui-container">
                  <div>
                    <h4>Selected impact type</h4>
                    <AdminSelectIcons
                      icons={[
                        {
                          iconName: "waves",
                          title: "Ocean-bound plastic cleanup",
                          subtitle: "Empower AS",
                          selected: false,
                        },
                        {
                          iconName: "park",
                          title: "Mangrove tree planting",
                          subtitle: "Eden Reforestation Projects",
                          selected: true,
                        },
                        {
                          iconName: "coronavirus",
                          title: "Covid-19 Response Fund",
                          subtitle: "United Nations Foundation",
                          selected: false,
                        },
                      ]}
                    />
                  </div>
                  <div>
                    <h4>Level of impact</h4>
                    <AdminSelectIcons
                      icons={[
                        {
                          title: "2 trees planted",
                          subtitle: "Removes 24.5 kg CO₂ annually",
                          selected: false,
                        },
                        {
                          title: "5 trees planted",
                          subtitle: "Removes 61.5 kg CO₂ annually",
                          selected: true,
                        },
                        {
                          title: "10 trees planted",
                          subtitle: "Removes 123 kg CO₂ annually",
                          selected: false,
                        },
                        {
                          title: "25 trees planted",
                          subtitle: "Removes 307.5 kg CO₂ annually",
                          selected: false,
                        },
                      ]}
                    />
                  </div>
                </div>
              </ShopifyAdmin>
            </CardImage>
            <Caption>Generate badges for your sales pages</Caption>
            <CardImage>
              <ProductPage scale={badgeScale}>
                <ProductImage scale={badgeScale} src="/tshirt-blank.png" />
                <ProductText scale={badgeScale}>
                  <span>Landmark Crew Neck</span>
                  <span>$19</span>
                  <BadgeOuter
                    scale={badgeScale}
                    style={{
                      backgroundColor: "#c8f3d3",
                      borderColor: "#52dd7c",
                    }}
                  >
                    <BadgeIcon
                      scale={badgeScale}
                      src="https://cdn.verdn.com/badge/tree.png"
                    />
                    <BadgeText scale={badgeScale}>
                      <span className="badgeTitle">
                        Plants 5 mangrove trees
                      </span>
                      <span className="badgeSubtitle">
                        The tree planting operations provide crucial income to
                        local communities. You can track your impact after
                        purchase.
                      </span>
                    </BadgeText>
                  </BadgeOuter>
                  <PoweredBy
                    scale={badgeScale}
                    src="https://cdn.verdn.com/branding/powered-by-black.png"
                  />
                </ProductText>
              </ProductPage>
            </CardImage>
          </Half>
        </Halves>
      </Wrapper>
    </SectionContainer>
  );
};

export default PledgeSection;
