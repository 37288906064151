import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import {
  Section,
  Wrapper,
  Emoji,
  Halves,
  Half,
  ContextButton,
} from "../layout";
import Media from "../media";

const SectionContainer = styled(Section)`
  background: white;
  position: relative;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const PersonCard = styled.div`
  flex-direction: column;
  width: 100%;
  background: white;
  display: flex;
  border-radius: 0.4rem;
  overflow: hidden;

  ${Media.Tablet`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.2rem;
  `}

  & img {
    ${Media.Tablet`
      height: 10rem;
      width: 10rem;
    `}
    background: #dcf3eb;
  }

  & h3 {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
  }

  & h4 {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
  }

  & p {
    font-size: 0.85rem;
  }

  & .text {
    padding: 1rem;
    flex: 1;
  }
`;

const ChrisImage = () => (
  <StaticImage
    src="../../images/people/v3/chris.jpg"
    alt="Chris"
    placeholder="tracedSVG"
  />
);

const KenleyImage = () => (
  <StaticImage
    src="../../images/people/v3/kenley.jpg"
    alt="Kenley"
    placeholder="tracedSVG"
  />
);

const CarlImage = () => (
  <StaticImage
    src="../../images/people/v3/carl.jpg"
    alt="Carl"
    placeholder="tracedSVG"
  />
);

const RoryImage = () => (
  <StaticImage
    src="../../images/people/v3/rory.jpg"
    alt="Rory"
    placeholder="tracedSVG"
  />
);

const personnel = [
  {
    name: "Christopher Mjelde",
    title: "CEO & Co-founder",
    image: <ChrisImage />,
    body: "Chris runs operations and client relations. Before Verdn he ran the London office of a performance marketing agency, and he has several years of experience within ESG and green energy.",
  },
  {
    name: "Rory McMeekin",
    title: "CTO & Co-founder",
    image: <RoryImage />,
    body: "Rory is a full stack developer and designer, with 10 years' experience making software. He has defined multiple startups' stacks, as well as previously being the technical and design lead for creative agency projects.",
  },
  {
    name: "Kenley Tomlin",
    title: "Founding Engineer",
    image: <KenleyImage />,
    body: "Kenley is a full stack developer with over 10 years' experience. He has worked at early stage startups and large corporates in a wide range of industries, including betting and gaming, equity crowd funding and e-commerce.",
  },
  {
    name: "Carl Langdon",
    title: "Founder Associate",
    image: <CarlImage />,
    body: "Carl supports operations and client relations at Verdn. A graduate from UCL and Jumpstart, he previously worked on his own environmental startup concept, as well as gaining experience in the asset management industry.",
  },
];

const TeamSection = ({ id }) => (
  <SectionContainer id={id}>
    <Wrapper>
      <Halves>
        <Half>
          <Emoji>people</Emoji>
          <h2>Meet the team</h2>
          <p>
            Verdn was started by Chris and Rory, who met at university and have
            worked together for the past decade. They have both spent several
            years in the green-tech space, and are now using their experience to
            create a service the world really needs.
          </p>
          <p>
            We are always eager to hear from like-minded individuals who'd wish
            to work on Verdn with us. If you're interested in joining our team,{" "}
            <ContextButton formId="t4RCnZJ3">get in touch</ContextButton>
          </p>
        </Half>
        <Half>
          <CardContainer>
            {personnel.map(({ name, title, image, body }) => (
              <PersonCard key={`${name}__${title}`}>
                {image}
                <div className="text">
                  <h3>{name}</h3>
                  <h4>{title}</h4>
                  <p>{body}</p>
                </div>
              </PersonCard>
            ))}
          </CardContainer>
        </Half>
      </Halves>
    </Wrapper>
  </SectionContainer>
);

export default TeamSection;
