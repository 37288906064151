import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { Section, Wrapper, Topper } from "../layout";
import Media from "../media";
import BackedByYc from "./YCBar";

const BrandSection = styled(Section)`
  /* position: relative; */
  text-align: center;
  padding-bottom: 4rem;
  /* background: #efefef; */
  /* border-bottom: 1px solid #ddd; */
  padding-top: 4rem;

  ${Media.Tablet`
    padding-top: 0rem;
  `}
`;

const LogoContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${Media.Laptop`
    justify-content: space-between;
  `}

  width: 100%;
  /* background: red; */
`;

const Logo = styled.div`
  width: 8rem;
  height: 4rem;
  opacity: 0.5;

  transition: opacity 1s;

  &:hover {
    opacity: 1;
  }

  & > div {
    height: 100%;
    width: 100%;
  }
`;

const brands = [
  {
    name: "ren-skincare",
    image: (
      <StaticImage
      src="../../images/home/client-logos/ren-skincare.png"
      alt="ren-skincare"
      placeholder="tracedSVG"
      />
    ),
  },
  {
    name: "gumbies",
    image: (
      <StaticImage
        src="../../images/home/client-logos/gumbies.png"
        alt="gumbies"
        placeholder="tracedSVG"
      />
    ),
  },
  {
    name: "shady-rays",
    image: (
      <StaticImage
        src="../../images/home/client-logos/shady-rays.png"
        alt="shady-rays"
        placeholder="tracedSVG"
      />
    ),
  },
  {
    name: "paper",
    image: (
      <StaticImage
        src="../../images/home/client-logos/paper.png"
        alt="paper"
        placeholder="tracedSVG"
      />
    ),
  },
  {
    name: "high-hope",
    image: (
      <StaticImage
        src="../../images/home/client-logos/high-hope.png"
        alt="high-hope"
        placeholder="tracedSVG"
      />
    ),
  },
  {
    name: "nialaya",
    image: (
      <StaticImage
        src="../../images/home/client-logos/nialaya.png"
        alt="nialaya"
        placeholder="tracedSVG"
      />
    ),
  },
];

const BrandBar = () => (
  <BrandSection>
    {/* <Topper /> */}
    <Wrapper>
      {/* <BackedByYc /> */}
      <h4>Trusted by brands across the globe</h4>
      <LogoContainer>
        {brands.map(({ name, image }) => (
          <Logo key={name}>{image}</Logo>
        ))}
      </LogoContainer>
    </Wrapper>
  </BrandSection>
);

export default BrandBar;
