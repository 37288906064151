import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import {
  Section,
  Wrapper,
  Topper,
  Emoji,
  Halves,
  Half,
  ContextButton,
} from "../layout";
import Media from "../media";

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Media.Laptop`
    position: absolute;
    perspective: 20rem;
    height: 40rem;
    top: 0;
  `}
`;

const Card = styled.div`
  border-radius: 1rem;
  border: 1px solid #eee;
  margin: 0.3rem;
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);

  overflow: hidden;

  display: flex;
  align-items: flex-start;

  .image-container {
    height: 12rem;
    width: 40%;
    padding: 0;
    overflow: hidden;
  }

  .image-container > div {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  & > div:not(.image-container) {
    flex: 2;
    padding: 1.2rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  max-width: 30rem;

  ${Media.Laptop`
    position: absolute;
    width: 23rem;
    
    transform: ${({ transformation }) => transformation};
    
    &:hover {
      transform: rotate3d(0, 0, 0, 0);
    }
    
    transition: transform 0.4s;
  `}
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 4rem;
    margin-right: 1rem;
  }

  h4 {
    color: white;
    flex: 1;
    margin: 0;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 0.4rem;
  span {
    width: 1.6rem;
    margin-right: 0.2rem;
  }
  p {
    color: white;
    font-size: 0.7rem;
    margin: 0;
    font-weight: 500;
  }
`;

const providerMeta = [
  {
    tag: "Ocean-bound plastic cleanup",
    color:
      "linear-gradient(to right top, #4679e1, #5a86e2, #6c93e3, #7e9fe4, #90ace4)",
    borderRadiusKey: "TopLeft",
    facilitator: "Empower AS",
    url: "https://empower.eco",
    locations: "Africa and Asia",
    top: 0,
    left: 3,
    // transform: "skew(-3deg, -6deg)",
    transform: "rotate3d(0,1,1,3deg)",
    image: (
      <StaticImage
        src="../../images/home/providers/ocean-plastic.jpeg"
        alt="Ocean-bound plastic cleanup"
      />
    ),
    zIndex: 60,
  },
  {
    tag: "Mangrove tree planting",
    color:
      "linear-gradient(to right top, #00965d, #2fa874, #4bb98a, #64cba1, #7dddb8)",
    borderRadiusKey: "TopRight",
    facilitator: "Eden Reforestation Projects",
    url: "https://empower.eco",
    locations: "Africa",
    top: 6,
    left: 18,
    transform: "rotate3d(1,0,1,-5deg)",
    image: (
      <StaticImage
        src="../../images/home/providers/tree-planting.jpeg"
        alt="Ocean-bound plastic cleanup"
      />
    ),
    zIndex: 50,
  },
  {
    tag: "Covid-19 Solidarity Fund",
    color:
      "linear-gradient(to right top, #d94963, #df6579, #e47e8f, #e796a4, #e9adb8)",
    borderRadiusKey: "BottomLeft",
    facilitator: "World Health Organization",
    url:
      "https://www.who.int/emergencies/diseases/novel-coronavirus-2019/donate",
    locations: "Worldwide",
    top: 24,
    left: 15,
    transform: "rotate3d(0.5,0.5,1,7deg)",
    image: (
      <StaticImage
        src="../../images/home/providers/covid-19.jpeg"
        alt="Ocean-bound plastic cleanup"
      />
    ),
    zIndex: 70,
  },
  {
    tag: "Coral Reef planting",
    color:
      "linear-gradient(to right top, #ff9583, #fe9f8f, #fcaa9b, #fab4a8, #f7beb4)",
    borderRadiusKey: "BottomRight",
    facilitator: "Coralive.org",
    locations: "Maldives",
    top: 16,
    left: 0,
    transform: "rotate3d(0,0,1,-4deg)",
    image: (
      <StaticImage
        src="../../images/home/providers/coral-planting.jpeg"
        alt="Ocean-bound plastic cleanup"
      />
    ),
    zIndex: 80,
  },
];

const CardsHalf = styled(Half)`
  ${Media.Laptop`
    position: absolute;
    width: 50%;
    left: 50%;
    z-index: 20;
  `}
`;

const ProvidersSection = ({ id }) => (
  <div style={{ position: "relative" }}>
    <Topper style={{ background: "#DBE8E1" }} />

    <Section
      id={id}
      style={{
        paddingTop: "4rem",
        paddingBottom: "16rem",
        background: "#DBE8E1",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <Wrapper>
        <Halves>
          <Half>
            <Emoji>public</Emoji>
            <h2>Transparent impact, from world-leading partners </h2>
            <p>
              Supporting social and environmental causes should be as easy as
              possible. We’ve established great partnerships so that you can
              skip the contracts, fees and manual setup.
            </p>
            <p>
              Are you an NGO, charity, or impact provider who wants to be on our
              platform?{" "}
              <ContextButton formId="t4RCnZJ3">Let us know</ContextButton>
            </p>
          </Half>
          <CardsHalf>
            <Cards>
              {providerMeta.map(
                ({
                  color,
                  borderRadiusKey,
                  tag,
                  facilitator,
                  locations,
                  top,
                  left,
                  transform,
                  image,
                  zIndex,
                }) => (
                  <Card
                    style={{
                      backgroundImage: color,
                      [`border${borderRadiusKey}Radius`]: "1rem",
                      top: `${top}rem`,
                      left: `${left}rem`,
                      ...(zIndex && { zIndex }),
                    }}
                    transformation={transform}
                  >
                    <div className="image-container">{image}</div>
                    <div>
                      <CardHeader>
                        <h4>{tag}</h4>
                      </CardHeader>
                      <InfoBlock>
                        <span className="material-icons">business</span>
                        <p>{facilitator}</p>
                      </InfoBlock>
                      <InfoBlock>
                        <span className="material-icons">location_on</span>
                        <p>{locations}</p>
                      </InfoBlock>
                    </div>
                  </Card>
                )
              )}
            </Cards>
          </CardsHalf>
        </Halves>
      </Wrapper>
    </Section>
  </div>
);

export default ProvidersSection;
