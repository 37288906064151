import React from "react";
import styled from "styled-components";

import { Section, Wrapper, Emoji, Caption, Halves, Half } from "../layout";

import Devices from "./Devices";

const SectionContainer = styled(Section)`
  position: relative;
  padding-top: 4rem;
`;

const CardImage = styled.img`
  /* position: absolute; */
  object-fit: cover;
  border-radius: 0.4rem;
  border: 1px solid #ddd;
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);
`;

const EngagementSection = ({ screenWidth }) => {
  const tabletScale = (() => {
    if (screenWidth < 600) {
      return 0.8;
    }
    return 1;
  })();

  return (
    <SectionContainer>
      <Wrapper>
        <Halves reverse>
          <Half>
            <Caption>Automated email touchpoints</Caption>
            <CardImage
              style={{
                width: "100%",
                borderRadius: "0.1rem",
                marginBottom: "0.4rem",
              }}
              src="/copy/email-bars/order-placed.png"
            />
            <CardImage
              style={{ width: "100%", borderRadius: "0.1rem" }}
              src="/copy/email-bars/ocean-bound.png"
            />
            <Caption>Impact tracking dashboards</Caption>
            <Devices hidePhone scale={tabletScale} />
          </Half>
          <Half>
            <Emoji>bolt</Emoji>
            <h2>Boost your customer engagement</h2>
            <p>
              Engaging your customers after they buy used to be difficult. Our
              automated marketing and tracking dashboards have click-through
              rates 10x higher than email marketing benchmarks.
            </p>
            <p>
              The impact tracking dashboards tell the story of your customers’
              impact, and showcase your brand’s commitment to sustainability.
            </p>
          </Half>
        </Halves>
      </Wrapper>
    </SectionContainer>
  );
};

export default EngagementSection;
