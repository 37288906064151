import React, { useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import color from "color";

import { Section, Wrapper, Topper, CenteredText } from "../layout";
import Media from "../media";

const SectionContainer = styled(Section)`
  padding: 4rem 0 4rem;
  position: relative;
  background: #344b47;

  h2 {
    color: white;
  }
`;

const QuoteBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuoteBody = styled.p`
  font-size: 1.25rem;
  line-height: 1.8rem;
  font-style: italic;
  padding: 1rem;
  color: white;
  font-weight: 300;
  /* margin-left: 2rem; */
`;

const BigQuote = styled.p`
  font-size: 4rem;
  line-height: 2rem;
  /* margin-bottom: -4rem; */
  color: white;
  opacity: 0.4;

  ${Media.Tablet`
    font-size: 8rem;
    line-height: 4rem;
  `}
`;

const StudyContainer = styled.div`
  /* max-width: 50rem; */
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);

  margin: auto;
  padding: 3rem 2rem;
  border-radius: 0.4rem;
  margin-top: 2rem;
  min-height: 20rem;

  display: flex;
  align-items: center;

  transition: background 1.2s;

  .gatsby-image-wrapper {
    object-fit: cover;
    width: 14rem;
    height: 10rem;
  }

  flex-direction: column-reverse;

  ${Media.Laptop`
    flex-direction: row;

    .gatsby-image-wrapper {
      width: 14rem;
      height: 10rem;
      margin-left: auto;
    }
  `}
`;

const Logos = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  flex-wrap: wrap;

  ${Media.Laptop`
    justify-content: space-evenly;
  `}

  img {
    width: 8rem;
    height: 6rem;
    object-fit: cover;
    transition: opacity 0.4s;
    cursor: pointer;

    margin: 1rem;

    ${Media.Laptop`
      margin: 0;
    `}
  }

  img:hover {
    opacity: 1 !important;
  }
`;

const reviews = [
  {
    logoName: "shady-rays",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/shady-rays.png"
        alt="Shady Rays"
        placeholder="tracedSVG"
      />
    ),
    body:
      "Verdn is an awesome app. Chris and Rory have been fantastic to work with and our customers have loved following along with the Ocean Plastic Cleanup.",
    themeColor: "#1C1E20",
  },
  {
    logoName: "paper",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/paper.png"
        alt="Paper London"
        placeholder="tracedSVG"
      />
    ),
    body:
      "A beautiful and simple way to give back with purpose. Verdn has helped us narrow down our messaging as a company that values the idea of giving back in different\u00A0ways.",
    themeColor: "#E00166",
  },
  {
    logoName: "high-hope",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/high-hope.png"
        alt="High Hope Clothing"
        placeholder="tracedSVG"
      />
    ),
    body:
      "What a fantastic app and team behind it! We are always looking for new ways to be sustainable and our customers are loving this new feature of trackable impacts by buying products from our\u00A0store.",
    themeColor: "#C35B27",
  },
  {
    logoName: "ark-and-sea",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/ark-and-sea.png"
        alt="Ark And Sea"
        placeholder="tracedSVG"
      />
    ),
    body:
      "The team at Verdn have been fantastic to work with and assist setup of this app which is a brilliant solution for brands looking to make a sustainable impact while allowing customers to track this progress along the way.",
    themeColor: "#02A196",
  },
  {
    logoName: "one-choice",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/one-choice.png"
        alt="One Choice Apparel"
        placeholder="tracedSVG"
      />
    ),
    body:
      "Amazing service, great value and Chris from customer service is brilliant. Would thoroughly recommend Verdn.",
    themeColor: "#111111",
  },
  {
    logoName: "ren-skincare",
    logoImage: (
      <StaticImage
        src="../../images/home/client-logos/light/ren-skincare.png"
        alt="Ren Skincare"
        placeholder="tracedSVG"
      />
    ),
    body: "The team was especially drawn to Verdn because there was nothing else like this, and the transparency aspect of the impact rewards was truly bridging the gap between consumers and our brand’s sustainability\u00A0messages.",
    themeColor: "#87B09A"
  }
];

const CaseStudies = ({ id }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { logoName, logoImage, body, themeColor } = reviews[selectedIndex];

  return (
    <SectionContainer id={id}>
      <Topper style={{ background: "#344B47" }} />
      <Wrapper>
        <CenteredText>
          {/* <Emoji>reviews</Emoji> */}
          <h2>What our customers say</h2>
        </CenteredText>
        <StudyContainer
          style={{
            background: `linear-gradient(to top right, ${color(themeColor)
              .darken(0.2)
              .hex()}, ${color(themeColor).lighten(0.4).hex()})`,
          }}
        >
          <QuoteBlock>
            <BigQuote
              style={{
                alignSelf: "flex-start",
                marginTop: "1rem",
                // marginRight: "-1rem",
              }}
            >
              &ldquo;
            </BigQuote>
            <QuoteBody>{body}</QuoteBody>
            <BigQuote
              style={{
                alignSelf: "flex-end",
                marginBottom: "-1rem",
                marginLeft: "-1rem",
              }}
            >
              &rdquo;
            </BigQuote>
          </QuoteBlock>
          {logoImage}
        </StudyContainer>
        <Logos>
          {reviews.map(
            ({ logoName: passedLogoName, logoImage: image }, index) => (
              <div
                key={passedLogoName}
                onClick={() => setSelectedIndex(index)}
                style={{
                  opacity: index === selectedIndex ? 0.8 : 0.3,
                  borderTop:
                    index === selectedIndex
                      ? "2px solid white"
                      : "2px solid transparent",
                }}
              >
                {image}
              </div>
            )
          )}
        </Logos>
      </Wrapper>
    </SectionContainer>
  );
};

export default CaseStudies;
