import React from "react";
import styled from "styled-components";

import { Section, Wrapper, CenteredText, Emoji, Topper } from "../layout";
import Media from "../media";

const SectionContainer = styled(Section)`
  padding-top: 4rem;
  position: relative;
  background: #fafafa;
  /* border-top: 1px solid #ddd; */
`;

const IconRows = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;

  ${Media.Tablet`
    flex-direction: column;
  `}
`;

const IconRow = styled.div`
  display: flex;
  margin-bottom: 1.6rem;

  flex-direction: column;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }

  ${Media.Tablet`
    flex-direction: row;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }

  `}
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid orange; */
  flex: 1;

  ${Media.Tablet`
    &:first-child {
      margin-right: 1.6rem;
    }

    &:last-child {
      margin-left: 1.6rem;
    }
  `}

  img {
    width: 4.2rem;
    height: 4.2rem;
    margin-left: -0.5rem;
    /* margin-bottom: 1rem; */
  }
`;

const IconHeading = styled.p`
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
`;

const IconBody = styled.p`
  font-size: 0.85rem;
`;

const icons = [
  [
    {
      imageName: "shopping_basket",
      title: "Pledge with your sales",
      body:
        "Effortlessly embed impact pledges (e.g. a pledge to plant 5 trees) into any of your products or services.",
    },
    {
      imageName: "volunteer_activism",
      title: "Give-back easily",
      body:
        "Support world-leading NGOs and providers with automated invoicing based on sales. No contracts or setup fees.",
    },
    {
      imageName: "mark_email_read",
      title: "Automated marketing",
      body:
        "Automatically engage your customers with branded emails and dashboards that let them track the impact of their purchase.",
    },
  ],
  [
    {
      imageName: "location_searching",
      title: "Granular impact tracking",
      body:
        "Each of your customers gets a unique tracking dashboard that updates over time with data from our impact partners.",
    },
    {
      imageName: "code_off",
      title: "No-code tools",
      body:
        "Display customizable badges on your product pages, and live counters on your landing page. (Shopify app only)",
    },
    {
      imageName: "insights",
      title: "Data insights (coming soon)",
      body:
        "Learn how your customers engage with their impact. Get actionable data to optimize your sales of sustainable products. (coming soon).",
    },
  ],
];

const TheWhatSection = () => (
  <SectionContainer>
    <Topper style={{ background: "#fafafa" }} />
    <Wrapper>
      <CenteredText>
        <h2>Reasons to try Verdn</h2>
      </CenteredText>

      <IconRows>
        {icons.map((iconRow, index) => (
          <IconRow key={`${index}_row`}>
            {iconRow.map(({ imageName, title, body }) => (
              <Icon key={title}>
                <Emoji small>{imageName}</Emoji>
                <IconHeading>{title}</IconHeading>
                <IconBody>{body}</IconBody>
              </Icon>
            ))}
          </IconRow>
        ))}
      </IconRows>
    </Wrapper>
  </SectionContainer>
);

export default TheWhatSection;
