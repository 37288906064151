import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { PopupButton } from "@typeform/embed-react";

import Media from "../media";
import { Wrapper } from "../layout";
import Devices from "./Devices";
import { NAVBAR_HEIGHT_DESKTOP } from "../../constants";
import { track } from "../../utils/analytics";

const HeroContainer = styled.div`
  background-image: linear-gradient(
    to right top,
    #005a5c,
    #007670,
    #009380,
    #00b08b,
    #38cd91
  );
  color: white;
  min-height: 80vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-top: 8rem;
  position: relative;
  ${Media.Tablet`
    margin-bottom: -${NAVBAR_HEIGHT_DESKTOP};
    top: -${NAVBAR_HEIGHT_DESKTOP};
  `}
`;

const HeroToppo = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  top: 0;
  left: 0;
  opacity: 0.03;
`;

const HeroWrapper = styled(Wrapper)``;

const Halves = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  ${Media.Laptop`
    flex-direction: row;
  `}
`;

const LeftHalf = styled.div`
  padding: 0rem 0 8rem;
  width: calc(100% - 4rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  /* background-color: gray; */

  h1 {
    line-height: 3rem;
    margin-bottom: 1.6rem;
    font-size: 2.8rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.6rem;
  }

  h2.shock {
    margin-top: 1rem;
    font-weight: 400;
  }

  p {
    max-width: 600px;
  }

  ${Media.Laptop`
    width: 50%;
    margin: 0;
    
    h1 {
    font-size: 3.6rem;
    line-height: 3.8rem;
  }
  `}
`;

const RightHalf = styled.div`
  height: calc(100% - 5rem);
  width: 100%;
  display: none;
  /* top: 2.5rem; */
  /* background-color: red; */
  justify-content: center;
  flex-direction: column;
  /* overflow: auto; */
  z-index: 200;
  /* padding-top: 8rem; */
  padding-left: 2rem;

  ${Media.Laptop`
  display: flex;
    width: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  /* height: 60vh; */
  `}
`;

const ButtonContainer = styled.div`
  display: flex;

  a:first-child {
    margin-right: 1rem;
  }
`;

const ShopifyButton = styled.a`
  padding: 0.6rem 1rem;
  background: ${({ ghost }) => (ghost ? `rgba(255,255,2550,0.2)` : "white")};
  color: ${({ ghost }) => (ghost ? `white` : "#023232")};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border-radius: 0.2rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background: ${({ ghost }) =>
      ghost ? `rgba(255,255,2550,1.0)` : "rgba(255,255,255,0.9)"};
    color: ${({ ghost }) => (ghost ? `#023232` : "#023232")};
  }
`;

const Hero = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async ({ email, winnie }) => {
    console.log(email);
    if (errors.email) {
      return;
    }
    setIsLoading(true);
    await fetch("https://snowboard.verdn.com/web-signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, winnie }),
      // mode: "no-cors",
    });
    setHasSubmitted(true);
  };

  return (
    <HeroContainer>
      <HeroToppo src="/test-toppo.png" />
      <HeroWrapper>
        <Halves>
          <LeftHalf>
            <h1>Instant sustainability with automated storytelling.</h1>
            <p>
              <strong>Increase customer engagement 10x</strong> by attaching
              environmental pledges to any of your products or services. Let
              your customers track their own impact automatically, after
              purchase, with updates from world-leading providers.
            </p>
            <ButtonContainer>
              <ShopifyButton
                href="/#section_get_started"
                onClick={() => {
                  track("Landing Page Sales Intent Interaction", {
                    label: "Get started button"
                  });
                }}
              >
                Get started
              </ShopifyButton>
              <PopupButton
                id="t4RCnZJ3"
                style={{
                  border: "none",
                  background: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                <ShopifyButton
                  ghost
                  onClick={() => {
                    track("Landing Page Sales Intent Interaction", {
                      label: "Contact sales button"
                    });
                  }}
                >
                  Contact sales
                </ShopifyButton>
              </PopupButton>
            </ButtonContainer>
          </LeftHalf>
          <RightHalf>
            <Devices />
          </RightHalf>
        </Halves>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default Hero;
