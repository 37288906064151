import React from "react";
import styled from "styled-components";
import color from "color";

const Inner = styled.div`
  width: ${({ scale }) => scale * (24 - 0.8)}rem;
  height: ${({ scale }) => scale * (33 - 0.8)}rem;
  border-radius: 0.4rem;
  overflow: hidden;
  position: absolute;
  transition: opacity 1s;
`;

const TopBackground = styled.div`
  width: 100%;
  height: ${({ scale }) => scale * 10}rem;
  display: flex;
  align-items: center;
  padding: ${({ scale }) => scale * 1.5}rem;
  position: relative;
  z-index: 3;
  transition: background-color 1s;
`;

const ProductPhoto = styled.img`
  width: ${({ scale }) => scale * 7}rem;
  height: ${({ scale }) => scale * 7}rem;
  border-radius: ${({ scale }) => scale * 0.3}rem;
  object-fit: contain;
  object-position: center center;
  background: white;
`;

const ProductDetail = styled.div`
  /* width: 100%; */
  color: #222;
  margin-left: ${({ scale }) => scale * 1.2}rem;
  display: flex;
  flex-direction: column;

  & > span {
    font-size: ${({ scale }) => scale * 1}rem;
    margin-bottom: ${({ scale }) => scale * 0.4}rem;
  }
`;

const DetailPart = styled.div`
  display: flex;
  align-items: center;

  color: #444;
  font-size: ${({ scale }) => scale * 0.7}rem;

  span.material-icons {
    font-size: ${({ scale }) => scale * 0.9}rem;
    margin-right: ${({ scale }) => scale * 0.2}rem;
    width: ${({ scale }) => scale * 1}rem;
  }
`;

const RedeemButton = styled.div`
  padding: ${({ scale }) => scale * 0.2}rem ${({ scale }) => scale * 0.8}rem;
  margin-top: ${({ scale }) => scale * 0.6}rem;
  font-size: ${({ scale }) => scale * 0.6}rem;
  color: #888;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 8rem; */
  background: #eee;
  border: 2px solid #ccc;
  font-weight: 600;
  border-radius: 0.2rem;
`;

const TimelineSection = styled.div`
  width: 100%;
  padding: ${({ scale }) => scale * 1}rem ${({ scale }) => scale * 1.5}rem
    ${({ scale }) => scale * 1}rem ${({ scale }) => scale * 0.5}rem;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #222;
`;

const TimelineEntry = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ scale }) => scale * 1.5}rem;
  transition: opacity 0.7s;
  transition-delay: 0.5s;
`;

const TimelineCard = styled.div`
  padding: ${({ scale }) => scale * 1}rem;
  border-radius: 0.3rem;
  background: white;
  /* box-shadow: inset 0px -3px 3px 2px rgba(50, 50, 50, 0.1),
    0px 3px 3px 1px rgba(20, 20, 20, 0.2); */
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);
  position: relative;
  flex: 1;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  line-height: 100%;

  .card-heading {
    text-transform: uppercase;
    font-weight: 600;
    color: #777;
    font-size: ${({ scale }) => scale * 0.7}rem;
    margin-bottom: ${({ scale }) => scale * 0.4}rem;
  }

  .card-body {
    font-size: 0.6rem;
  }

  .card-image {
    width: 100%;
    height: ${({ scale }) => scale * 8}rem;
    border: 1px solid #eee;
    border-radius: 0.2rem;
    margin-top: ${({ scale }) => scale * 0.6}rem;
    object-fit: cover;
    object-position: center;
  }
`;

const TimelineDot = styled.div`
  width: ${({ scale }) => scale * 1.5}rem;
  height: ${({ scale }) => scale * 1.5}rem;
  border-radius: ${({ scale }) => scale * 1.5}rem;
  background: #ccc;
  margin-left: ${({ scale }) => scale * 0.375}rem;
  margin-right: ${({ scale }) => scale * 0.5}rem;
  margin-top: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 3px solid #ccc; */
  /* padding: 2px; */
  /* position: absolute; */
  /* top: -0.5rem;
  left: 0.5rem; */
  span {
    font-size: ${({ scale }) => scale * 1.1}rem;
  }
`;

const TimelineRail = styled.div`
  height: 100vh;
  left: ${({ scale }) => scale * 1.5}rem;
  border-left: 3px solid #ddd;
  position: absolute;
  top: 0;
`;

const TimelineShifter = styled.div`
  z-index: 2;
  transition: transform 1s;
`;

const Tablet = ({ merchant, topmostIndex, opacity, scale }) => {
  const {
    name,
    impactType,
    points,
    themeColor,
    product,
    timelineEntries: rawTimelineEntries,
  } = merchant;
  // const [topmostIndex, setTopmostIndex] = useState(2);
  const timelineEntries = rawTimelineEntries.slice();

  const currentOffset = timelineEntries
    .slice(topmostIndex + 1)
    .reduce((total, { height }) => total + height + 1.5, 0);

  // useInterval(() => {
  //   if (topmostIndex < timelineEntries.length - 1) {
  //     setTopmostIndex((tI) => tI + 1);
  //   } else {
  //     changeMerchant();
  //   }
  // }, 3e3);

  return (
    <Inner scale={scale} style={{ opacity }}>
      <TopBackground
        scale={scale}
        style={{
          backgroundColor: color(themeColor).darken(0.3).alpha(0.1).hsl(),
        }}
      >
        <ProductPhoto scale={scale} src={product.image} />
        <ProductDetail scale={scale}>
          <span>{product.name}</span>
          {[
            {
              icon: "store",
              text: name,
            },
            ...points,
          ].map(({ icon, text }) => (
            <DetailPart scale={scale} key={icon}>
              <span className="material-icons">{icon}</span>
              <span>{text}</span>
            </DetailPart>
          ))}
        </ProductDetail>
      </TopBackground>
      <TimelineSection scale={scale}>
        <TimelineRail scale={scale} />
        <TimelineShifter
          scale={scale}
          style={{ transform: `translateY(-${currentOffset}rem)` }}
        >
          {timelineEntries
            .slice()
            .map(({ title, body, icon, type, height, ...entry }, index) => (
              <TimelineEntry
                scale={scale}
                key={`${title}_${icon}`}
                style={{
                  height: `${height}rem`,
                  opacity: index <= topmostIndex ? 1 : 0,
                }}
              >
                <TimelineDot scale={scale}>
                  <span className="material-icons">{icon}</span>
                </TimelineDot>
                <TimelineCard scale={scale}>
                  <span className="card-heading">{title}</span>
                  <span className="card-body">{body}</span>
                  {type === "IMAGE" && (
                    <img className="card-image" src={entry.source} />
                  )}
                  {type === "REDEEM" && (
                    <RedeemButton scale={scale}>Redeem</RedeemButton>
                  )}
                </TimelineCard>
              </TimelineEntry>
            ))
            .reverse()}
        </TimelineShifter>
      </TimelineSection>
    </Inner>
  );
};

export default Tablet;
