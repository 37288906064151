import React from "react";
import styled from "styled-components";
import color from "color";

const FADE_LEVEL = 0.2;
const PHONE_SIZE = 1.8;

const PhoneInner = styled.div`
  width: ${8 * PHONE_SIZE - 0.8}rem;
  height: ${16 * PHONE_SIZE - 0.8}rem;
  background: #fff;
  border-radius: 1.5rem;
  border: 1px solid #f0f0f0;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 1s;
`;

const ShoppingBagIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={size}
    width={size}
    viewBox="0 0 24 24"
    fill={color}
  >
    <g>
      <rect fill="none" height="24" width="24" />
      <path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" />
    </g>
  </svg>
);

const Header = styled.div`
  /* opacity: 0.6; */
  width: 100%;
  height: 3rem;
  background: #fefefe;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 800;
  text-transform: uppercase;
  position: absolute;
  box-shadow: 0 2px 6px 0px rgba(20, 20, 20, 0.1);
  z-index: 100;
`;

const ClippedPhoto = styled.img`
  opacity: ${FADE_LEVEL * 2};
  width: 100%;
  margin-top: 10%;
  height: 35%;
  object-position: center center;
  object-fit: contain;
  padding-bottom: 0.4rem;
  /* background: #eee; */
`;

const GalleryBar = styled.div`
  opacity: ${FADE_LEVEL};
  display: flex;
  width: 100%;
  height: 2px;

  div {
    flex: 1;
    height: 2px;
    background: #ccc;
  }

  div:first-child {
    background: #aaa;
  }
`;

const Details = styled.div`
  opacity: ${FADE_LEVEL};
  width: 100%;
  position: relative;
  /* height: 4rem; */
  /* ${({ slim }) => slim && "height: 2rem"}; */
  color: black;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin-top: 0.7rem;
  /* overflow: hidden; */

  .title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: 0.2rem;
  }

  .price {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .shipping {
    font-size: 0.6rem;
    color: #777;
  }

  .subtitle {
    line-height: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
  }

  .body {
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
`;

const AddToBag = styled.div`
  width: 100%;
  padding: 0.4rem 0.2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;

  span {
    line-height: 1rem;
    margin: 0;
    padding: 0;
    margin-top: 0.14rem;
    margin-left: 0.4rem;
  }
`;

const BadgeOuter = styled.div`
  position: relative;
  width: calc(100% - 1rem);
  border-width: 2px;
  border-style: solid;
  border-radius: 0.3rem;
  margin: 0.6rem 0 0.2rem;
  display: flex;
  padding: 0.6rem 0.5rem;
  align-items: flex-start;
  transition: background-color 1s, border-color 1s;
`;

const PoweredBy = styled.img`
  height: 0.5rem;
  opacity: 0.8;
  align-self: flex-end;
  margin-right: 0.7rem;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
`;

const BadgeIcon = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 4rem;
  margin-right: 0.6rem;
`;

const BadgeText = styled.div`
  display: flex;
  flex-direction: column;
  color: black;

  .badgeTitle {
    font-size: 0.6rem;
    font-weight: 800;
    line-height: 0.8rem;
    margin-bottom: 0.1rem;
  }

  .badgeSubtitle {
    font-size: 0.55rem;
    font-weight: 400;
    line-height: 0.65rem;
  }
`;

const lineWidths = [...new Array(3)].map(() =>
  Math.round(Math.random() * 7 + 90, 2)
);

const SkeletonLines = styled.div`
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  position: relative;
  overflow: hidden;
`;

const SkeletonLine = styled.div`
  height: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 4px solid #aaa;
  /* position: absolute; */
  top: 0;
  left: 0;
`;

const Phone = ({ merchant, opacity }) => {
  const { name, color: blackColor, themeColor, product, badge } = merchant;
  return (
    <PhoneInner style={{ opacity }}>
      <Header style={{ color: `${blackColor}33` }}>
        {name}
        <div style={{ marginLeft: "auto", marginTop: "0.4rem" }}>
          <ShoppingBagIcon size="1rem" color={`${blackColor}33`} />
        </div>
      </Header>
      <ClippedPhoto src={product.image} />
      <GalleryBar>
        <div />
        <div />
        <div />
      </GalleryBar>
      <Details slim style={{ height: "2rem" }}>
        <span className="title">{product.name}</span>
        <span className="price">{product.price}</span>
      </Details>
      <BadgeOuter
        style={{
          backgroundColor: color(themeColor).hex(),
          borderColor: color(themeColor).darken(0.2).hex(),
        }}
      >
        <BadgeIcon src={badge.icon} />
        <BadgeText>
          <span className="badgeTitle">{badge.title}</span>
          <span className="badgeSubtitle">{badge.body}</span>
        </BadgeText>
      </BadgeOuter>
      <PoweredBy src="https://cdn.verdn.com/branding/powered-by-black.png" />
      <Details>
        <AddToBag style={{ backgroundColor: blackColor }}>
          <ShoppingBagIcon size="0.7rem" color="white" />
          <span>Add to Bag</span>
        </AddToBag>
        <span className="subtitle">Description</span>
        <SkeletonLines>
          {lineWidths.map((width, index) => {
            return (
              <SkeletonLine
                key={`${width}_${index}`}
                style={{ width: `${width}%` }}
              />
            );
          })}
        </SkeletonLines>
      </Details>
    </PhoneInner>
  );
};

export default Phone;
