import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import PhoneContent from "./Phone";
import TabletContent from "./Tablet";

import useInterval from "../../hooks/useInterval";

// import Media from "../../media";

const merchantExamples = [
  {
    name: "Acme Tees",
    impactType: "tree planting",
    color: "#0D2539",
    themeColor: "#c8f3d3",
    points: [
      { icon: "park", text: "2 trees planted" },
      { icon: "air", text: "25 kg CO₂e" },
    ],
    product: {
      name: "Landmark Crew Neck",
      price: "$19",
      image: "/tshirt-blank.png",
      description:
        "The landmark tee is the latest in our line of authentic, 100% cotton t-shirts, made in America with designs that we know you'll love.",
    },
    badge: {
      backgroundColor: "#c8f3d3",
      borderColor: "#52dd7c",
      title: "Plants 2 mangrove trees",
      body:
        "The tree planting operations provide crucial income to local communities. You can track your impact after purchase.",
      icon: "https://cdn.verdn.com/badge/tree.png",
    },
    timelineEntries: [
      {
        height: 5,
        title: "Tree planting started",
        type: "TEXT",
        icon: "update",
        body:
          "The funds for your pledge have been transferred to our tree planting provider.",
      },
      {
        height: 13,
        title: "Planting site assigned",
        type: "IMAGE",
        source: "/map-vilamatsa.png",
        icon: "location_on",
        body: "Your trees will be planted in Vilamatsa, Madagascar.",
      },
      {
        height: 4,
        title: "Trees planted",
        type: "TEXT",
        icon: "park",
        body: "Two trees have now been planted on your behalf.",
      },
      {
        height: 13,
        title: "About your trees",
        body: "Learn more about who planted your trees:",
        icon: "info",
        type: "IMAGE",
        source: "/eden-video.png",
      },
    ],
  },
  {
    name: "Specs & Co",
    impactType: "ocean-bound plastic cleanup",
    color: "#505457",
    themeColor: "#c8eaf3",
    points: [
      { icon: "waves", text: "5 kg ocean-bound plastic" },
      { icon: "water_drop", text: "400 plastic bottles equivalent" },
    ],
    product: {
      name: "Orford Shades",
      price: "$65",
      image: "/shades.png",
    },
    badge: {
      backgroundColor: "#c8eaf3",
      borderColor: "#52a5dd",
      title: "Recovers 5 kg of ocean-bound plastic",
      body:
        "Plastic is cleaned from rivers and shorelines, providing income to local waste-pickers. You can track your impact after purchase.",
      icon: "https://cdn.verdn.com/badge/bottle.png",
    },
    timelineEntries: [
      {
        height: 5,
        title: "Plastic collection initiated",
        type: "TEXT",
        icon: "update",
        body:
          "The funds for your pledge have been transferred to our ocean-bound plastic provider.",
      },
      {
        height: 13,
        title: "Collection point assigned",
        type: "IMAGE",
        source: "/map-srilanka.png",
        icon: "location_on",
        body: "Your plastic will be collected in Arugam Bay, Sri Lanka.",
      },
      {
        height: 4,
        title: "Ocean-bound plastic recovered",
        type: "TEXT",
        icon: "waves",
        body: "5 kg ocean-bound plastic has been collected.",
      },
      {
        height: 13,
        title: "About the collection point",
        body: "Learn more about who collects the plastic:",
        icon: "info",
        type: "IMAGE",
        source: "/empower-video.png",
      },
    ],
  },
].map(({ timelineEntries, ...merchantData }) => ({
  ...merchantData,
  timelineEntries: [
    {
      height: 5,
      title: "Product purchased",
      type: "TEXT",
      icon: "shopping_bag",
      body: `You purchased the ${
        merchantData.product.name
      } on ${moment().format("Do MMMM YYYY")}, with a pledge for ${
        merchantData.impactType
      }.`,
    },
    ...timelineEntries.slice(),
    {
      height: 7.5,
      title: "Double your pledge",
      body: `Shop again at ${merchantData.name} to double your ${merchantData.impactType} pledge for this purchase.`,
      icon: "redeem",
      type: "REDEEM",
    },
  ],
}));

const Container = styled.div`
  /* background: white; */
  position: relative;
  user-select: none;
  display: flex;
  height: 100vh;
`;

const PHONE_SIZE = 1.8;

const PhoneOuter = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${8 * PHONE_SIZE}rem;
  height: ${16 * PHONE_SIZE}rem;
  background: #fdfdfd;
  border-radius: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px -20px 20px 2px rgba(50, 50, 50, 0.1),
    0px 23px 28px 5px rgba(20, 20, 20, 0.2);
  z-index: 10;
`;

const TabletOuter = styled.div`
  position: absolute;
  width: ${({ scale }) => scale * 24}rem;
  height: ${({ scale }) => scale * 33}rem;

  background: rgba(255, 255, 255, 0.95);
  border-radius: 0.8rem;
  top: -2rem;
  left: 13rem;
  border: 1px solid #eee;
  box-shadow: inset 0px -10px 10px 2px rgba(50, 50, 50, 0.1),
    0px 23px 28px 5px rgba(20, 20, 20, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;

const DEFAULT_TOPMOST_INDEX = 1;

const Devices = ({
  hidePhone = false,
  hideTablet = false,
  forceIndex,
  scale = 1,
}) => {
  const [merchantIndex, setMerchantIndex] = useState(
    forceIndex != null ? forceIndex : 0
  );
  const currentMerchant = merchantExamples[merchantIndex];

  const [topmostIndex, setTopmostIndex] = useState(DEFAULT_TOPMOST_INDEX);

  useInterval(() => {
    if (forceIndex != null) return;
    if (topmostIndex < currentMerchant.timelineEntries.length - 1) {
      setTopmostIndex((tI) => tI + 1);
    } else {
      // changeMerchant();
      if (merchantIndex === merchantExamples.length - 1) {
        setMerchantIndex(0);
      } else {
        setMerchantIndex((mI) => mI + 1);
      }
      setTopmostIndex(DEFAULT_TOPMOST_INDEX);
    }
  }, 3e3);

  return (
    <Container
      style={{
        ...(hidePhone && { height: "26rem" }),
        ...(hideTablet && { height: "20rem" }),
      }}
    >
      {!hideTablet && (
        <TabletOuter
          scale={scale}
          style={{ ...(hidePhone && { left: 0, top: 0 }) }}
        >
          {merchantExamples.map((merchant, index) => (
            <TabletContent
              key={merchant.name}
              scale={scale}
              opacity={index === merchantIndex ? 1 : 0}
              merchant={merchant}
              topmostIndex={(() => {
                if (
                  index !== merchantIndex &&
                  topmostIndex === DEFAULT_TOPMOST_INDEX
                ) {
                  return merchant.timelineEntries.length - 1;
                }
                return index === merchantIndex
                  ? topmostIndex
                  : DEFAULT_TOPMOST_INDEX;
              })()}
            />
          ))}
        </TabletOuter>
      )}
      {!hidePhone && (
        <PhoneOuter style={{ ...(hideTablet && { left: 0, top: 0 }) }}>
          {merchantExamples.map((merchant, index) => (
            <PhoneContent
              key={merchant.name}
              opacity={index === merchantIndex ? 1 : 0}
              merchant={merchant}
            />
          ))}
        </PhoneOuter>
      )}
    </Container>
  );
};

export default Devices;
