import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { Section, Wrapper, Topper } from "../layout";
import Media from "../media";

const Winglet = styled.div`
  width: 1.6rem;
  border: 1px solid #ddd;
  margin: 0 1rem;
`;

const YcSection = styled(Section)`
  position: relative;
  text-align: center;
  padding-bottom: 2rem;
  /* background: #efefef; */
  /* border-bottom: 1px solid #ddd; */
  padding-top: 4rem;
  ${Media.Tablet`
    padding-top: 0rem;
  `}
`;

const YcContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & span {
    font-size: 0.9rem;
  }

  & img {
    width: 8rem;
    object-fit: contain;
    margin-right: -0.7rem;
  }
  margin-bottom: 3rem;

  /* opacity: 0.5;

  transition: opacity 1s;

  &:hover {
    opacity: 1;
  } */

  /* margin-top: -5vh; */
  /* position: relative; */
  /* z-index: 100000; */
`;

const BackedByYc = () => (
  <YcSection>
    {/* <Topper /> */}
    <Wrapper>
      <YcContainer>
        <Winglet />
        <span>Backed by</span>
        <a
          href="https://www.ycombinator.com/companies/verdn"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/yc-logo.png"
            alt="Y Combinator logo"
            placeholder="tracedSVG"
          />
        </a>
        <Winglet />
      </YcContainer>
    </Wrapper>
  </YcSection>
);

export default BackedByYc;
