import React from "react";
import styled from "styled-components";
import moment from "moment";
import color from "color";

import {
  Section,
  Wrapper,
  Topper,
  Emoji,
  Caption,
  Halves,
  Half,
} from "../layout";
import Media from "../media";

const SectionContainer = styled(Section)`
  position: relative;
  padding-top: 4rem;
  padding-bottom: 12rem;
`;

const Chip = styled.span`
  padding: ${({ scale }) => scale * 0.8}rem ${({ scale }) => scale * 0.7}rem;
  font-size: ${({ scale }) => scale * 0.8}rem;
  background: #ddd;
  border-radius: 10rem;
  margin: 0 ${({ scale }) => scale * 0.4}rem;
  color: #005a5c;
  background: #b7f2f4;
  line-height: 100%;
`;

const StatusChip = styled.span`
  padding: 0.3rem 0.8rem;
  font-size: 1.1rem;
  background: #ddd;
  border-radius: 10rem;
  margin: 0 0.4rem;
  color: #005a5c;
  background: #b7f2f4;
`;

const ChipSet = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  span {
    margin-left: 0;
    margin-right: ${({ scale }) => scale * 0.6}rem;
    margin-bottom: ${({ scale }) => scale * 0.6}rem;
    font-size: ${({ scale }) => scale * 0.6}rem;
    padding: ${({ scale }) => scale * 0.5}rem ${({ scale }) => scale * 0.6}rem;
  }
`;

const NeueChip = ({ scale, sub, body, color: foregroundColor }) => {
  const backgroundColor = color(foregroundColor).lightness(90).hex();
  return (
    <Chip scale={scale} style={{ backgroundColor, color: foregroundColor }}>
      {sub}: <strong>{body}</strong>
    </Chip>
  );
};

const CardImage = styled.div`
  border-radius: 0.4rem;
  border: 1px solid #ddd;
  box-shadow: 0 7px 7px 0px rgba(20, 20, 20, 0.05);
  background: white;
  overflow: hidden;
  background-color: #f6f6f7;
  padding: 1rem;
`;

const DataDashboard = styled.figure`
  width: 100%;
  /* max-width: 30rem; */
  height: 100%;

  .top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: ${({ scale }) => scale * 0.4}rem;
    margin-bottom: ${({ scale }) => scale * 1}rem;
  }

  .title {
    font-weight: 600;
    font-size: ${({ scale }) => scale * 0.8}rem;
  }

  .date {
    font-weight: 400;
    font-size: ${({ scale }) => scale * 0.7}rem;
  }

  .material-icons {
    font-size: ${({ scale }) => scale * 1}rem;
    line-height: ${({ scale }) => scale * 1}rem;
    margin-right: ${({ scale }) => scale * 0.4}rem;
  }
`;

const DataCardBody = styled.div`
  background: white;
  padding: ${({ scale }) => scale * 0.6}rem;
  border-radius: ${({ scale }) => scale * 0.2}rem;
  box-shadow: 0 3px 3px 0px rgba(20, 20, 20, 0.05);
  margin: ${({ scale }) => scale * 0.5}rem 0;

  & .single {
    height: ${({ scale }) => scale * 4}rem;
  }

  & .double {
    height: ${({ scale }) => scale * 8}rem;
  }
`;

const CardTitle = styled.span`
  color: #777;
  font-weight: 500;
  font-size: ${({ scale }) => scale * 0.7}rem;
  text-transform: uppercase;
  line-height: ${({ scale }) => scale * 0.6}rem;
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ scale }) => scale * 1}rem;
`;

const DataCard = ({
  scale,
  className,
  title,
  flex,
  children,
  spaceRight = false,
  spaceLeft = false,
  center = false,
}) => (
  <DataCardBody
    scale={scale}
    style={{
      flex,
      marginRight: spaceRight && "0.5rem",
      marginLeft: spaceLeft && "0.5rem",
    }}
  >
    <CardTitle scale={scale}>{title}</CardTitle>
    <CardContent
      scale={scale}
      className={className}
      style={{
        ...(center && { alignItems: "center", justifyContent: "center" }),
      }}
    >
      {children}
    </CardContent>
  </DataCardBody>
);

const CardRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const BigNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: ${({ scale }) => scale * 0.6}rem ${({ scale }) => scale * 1}rem
    ${({ scale }) => scale * 1}rem;

  & span:first-child {
    font-size: ${({ scale }) => scale * 1}rem;
    font-weight: 600;
  }

  & span:last-child {
    font-size: ${({ scale }) => scale * 0.6}rem;
    text-transform: uppercase;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  padding: ${({ scale }) => scale * 1}rem;
  margin-bottom: ${({ scale }) => scale * 0.6}rem;
`;

const ChartAxes = styled.div`
  padding: 0 ${({ scale }) => scale * 1}rem;
  width: 100%;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-color: #ccc;
`;

const ChartBarContainer = styled.div`
  height: ${({ size, scale }) => size * scale}rem;
  /* display: flex; */
  /* flex-direction: column; */

  span {
    display: none;
  }

  ${Media.Tablet`
    span {
      line-height: 100%;
      text-align: center;
      font-size: ${({ scale }) => scale * 0.4}rem;
    }
  `}
`;

const ChartBarInner = styled.div`
  width: ${({ scale }) => scale * 2}rem;
  height: 100%;
  background-color: ${({ barColor }) => barColor};
`;

const ChartBar = ({ scale, size, intensity, color: barColor, label }) => (
  <ChartBarContainer scale={scale} size={size}>
    <ChartBarInner
      scale={scale}
      style={{ opacity: intensity }}
      barColor={barColor}
    />
    <span>{label}</span>
  </ChartBarContainer>
);

const ArrowContainer = styled.div`
  width: ${({ scale }) => scale * 2}rem;
  height: ${({ scale }) => scale * 3}rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartArrow = ({ scale }) => (
  <ArrowContainer scale={scale}>
    <span className="material-icons">east</span>
  </ArrowContainer>
);

const CampaignBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: ${({ scale }) => scale * 0.7}rem;
`;

const CampaignIconBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ scale }) => scale * 0.4}rem;

  & span.material-icons {
    color: #888;
  }
`;

const CampaignIcon = ({ scale, iconName, label }) => (
  <CampaignIconBlock scale={scale}>
    <span className="material-icons">{iconName}</span>
    <span>{label}</span>
  </CampaignIconBlock>
);

const DataSection = ({ screenWidth }) => {
  const startDate = moment().subtract(30, "days").format("Do MMM YYYY");
  const endDate = moment().format("Do MMM YYYY");

  const scale = (() => {
    if (screenWidth < 450) {
      return 0.4;
    }
    if (screenWidth < 680) {
      return 0.6;
    }
    return 1;
  })();

  return (
    <SectionContainer>
      <Topper style={{ backgroundColor: "white" }} />
      <Wrapper>
        <Halves reverse>
          <Half>
            <Caption>Gain insights at a glance (coming soon)</Caption>
            <CardImage>
              <DataDashboard scale={scale}>
                <div className="top-bar">
                  <span className="material-icons">business</span>
                  <span className="title">Acme Co. </span>
                  <span
                    className="material-icons"
                    style={{ marginLeft: "auto" }}
                  >
                    date_range
                  </span>
                  <span className="date">
                    ({startDate} – {endDate})
                  </span>
                </div>
                <CardRow scale={scale}>
                  <DataCard
                    scale={scale}
                    className="single"
                    flex={0.5}
                    spaceRight
                    title="Key metrics"
                    center
                  >
                    <BigNumber scale={scale}>
                      <span>27%</span>
                      <span>Email CTR</span>
                    </BigNumber>
                    <BigNumber scale={scale}>
                      <span>3%</span>
                      <span>Repurchase rate</span>
                    </BigNumber>
                  </DataCard>
                  <DataCard
                    scale={scale}
                    className="single"
                    flex={0.5}
                    spaceLeft
                    title="Popular segments"
                  >
                    <ChipSet scale={scale}>
                      <NeueChip
                        scale={scale}
                        sub="Age"
                        body="25–35"
                        color="#011627"
                      />
                      <NeueChip
                        scale={scale}
                        sub="Geography"
                        body="Scandinavia"
                        color="#DF85A2"
                      />
                      <NeueChip
                        scale={scale}
                        sub="Interests"
                        body="Hiking"
                        color="#00B98E"
                      />
                      <NeueChip
                        scale={scale}
                        sub="Budget"
                        body="< $50"
                        color="#FF8847"
                      />
                    </ChipSet>
                  </DataCard>
                </CardRow>
                <CardRow scale={scale}>
                  {" "}
                  <DataCard
                    scale={scale}
                    className="double"
                    flex={0.7}
                    spaceRight
                    title="Engagement flow"
                  >
                    <ChartContainer scale={scale}>
                      <ChartAxes scale={scale}>
                        <ChartBar
                          scale={scale}
                          size={6}
                          color="#569D9F"
                          intensity={1}
                          label="Email open"
                        />
                        <ChartArrow scale={scale} />
                        <ChartBar
                          scale={scale}
                          size={4}
                          color="#569D9F"
                          intensity={0.8}
                          label="Email click"
                        />
                        <ChartArrow scale={scale} />
                        <ChartBar
                          scale={scale}
                          size={3}
                          color="#569D9F"
                          intensity={0.6}
                          label="Web click"
                        />
                        <ChartArrow scale={scale} />
                        <ChartBar
                          scale={scale}
                          size={1}
                          color="#569D9F"
                          intensity={0.4}
                          label="Social share"
                        />
                      </ChartAxes>
                    </ChartContainer>
                  </DataCard>
                  <DataCard
                    scale={scale}
                    className="double"
                    flex={0.3}
                    spaceLeft
                    title="Campaign Status"
                    center
                  >
                    <CampaignBlock scale={scale}>
                      <CampaignIcon
                        scale={scale}
                        iconName="auto_awesome_motion"
                        label="3 email flows"
                      />
                      <CampaignIcon
                        scale={scale}
                        iconName="share"
                        label="Share tracking on"
                      />
                      <CampaignIcon
                        scale={scale}
                        iconName="local_offer"
                        label="2x impact incentive"
                      />
                    </CampaignBlock>
                  </DataCard>
                </CardRow>
              </DataDashboard>
            </CardImage>
          </Half>
          <Half>
            <Emoji>insights</Emoji>
            <StatusChip style={{ margin: "1rem 0" }}>Coming soon</StatusChip>
            <h2 style={{ marginTop: "1rem" }}>
              Gain new insights from your customers
            </h2>
            <p>
              See how your customers engage with their impact tracking
              dashboards, and the automated marketing.
            </p>
            <p>
              Uncover important metrics and identify conscious customer
              segments, so that you can sell more of your sustainable products
            </p>
          </Half>
        </Halves>
      </Wrapper>
    </SectionContainer>
  );
};

export default DataSection;
